.App {
    height: 100vh;
    text-align: center;
}

.App-header h1 { 
    font-size: 60px;
}

.App-header img {
    max-width: 17%;
    height: auto;
}

.App-header ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.App-header ul li {
    margin: 10px 0;
    font-size: 20px;
}

/* For non - mobile */
@media (min-width: 768px) { 

    .App-header {
        position: relative;
        top: 15vh;
    }

    .App-header ul {
        flex-direction: row;
        justify-content: center;
    }

    .App-header ul li {
        margin: 0px 10px;
    }
}

/* For mobile */
@media (max-width: 767px) { 
    .App-header img { 
        display: none;
    }
}